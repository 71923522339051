$(document).ready(function () {

	//responsive menu
	$('header nav').on('click', 'button#openMenu', function(){
		var $this = $(this);
		$this.toggleClass('active');

		var $header = $('header#mainHeader');

		var status = $this.hasClass('active');

		if (status) {
			//$this.parent().find('.links').slideDown();
			$header.addClass('menuAbre');
		} else {
			//$this.parent().find('.links').slideUp();
			$header.removeClass('menuAbre');
		}
	});

	var $decisaoTop = $('#empresa .decisoes').position().top;
	// console.log($decisaoTop);

	$(window).scroll(function(){
		var top = $(window).scrollTop();
		if(top > $decisaoTop-200){
			$('header#mainHeader').addClass('scrolla');
		}else{
			$('header#mainHeader').removeClass('scrolla');
		}
	});


	//se a janela for maior que 768px, mostra o menu novamente
	//se não, esconde
	if ( $(window).width() >= 975 ) {
		$('header#mainHeader').removeClass('menuAbre');
		$('header#mainHeader button#openMenu').removeClass('active');
		$('body').removeClass('noScroll');
	}

	//se fizer o resize e a janela for maior que 768px, mostra o menu novamente
	//se não, esconde
	$(window).on('resize', function() {
		if ( $(window).width() >= 975 ) {
			$('header#mainHeader').removeClass('menuAbre');
			$('header#mainHeader button#openMenu').removeClass('active');
			$('body').removeClass('noScroll');
		}
	});

	//variaveis
	var $height = $(window).height();
	var scrollTarget = true;

	$(document)
		.on('click', 'header nav a[href*="#"]', function() {
			$(this).parent().parent().find('a').removeClass("active");
			if ( this.hash && this.pathname === location.pathname ) {
				$.bbq.pushState( "#/" + this.hash.slice(1) );
				$(this).addClass("active");
				return false;
			}
		})
		.ready(function() {
			$(window).bind('hashchange', function(event) {
				var tgt = location.hash.replace(/^#\/?/,'');
				if ( document.getElementById(tgt) && scrollTarget ) {
					$.smoothScroll({
						scrollTarget: '#' + tgt,
						speed: 1500
					});
				}
			});

			$(window).trigger('hashchange');
		});

	$(window).scroll(function(){
		var $empresa = $('#empresa').offset().top, //0
			$equipe = $('#equipe').offset().top, //1
			$filosofia = $('#filosofia').offset().top, //2
			$solucoes = $('#solucoes').offset().top, //3
			$diferenciais = $('#diferenciais').offset().top, //4
			$clientes = $('#clientes').offset().top, //5
			$depoimentos = $('#depoimentos').offset().top, //6
			$contato = $('#contato').offset().top,
			top = $(window).scrollTop(),
			tolerancia = 100;

		var $headNav = $('header nav').find('a');

		$headNav.removeClass('active');
		if($empresa <= top && $equipe-tolerancia >= top){
			$headNav.eq(0).addClass('active');
			$.bbq.pushState( "#/empresa");
		} else if($equipe-tolerancia <= top && $filosofia-tolerancia >= top ){
			$headNav.eq(1).addClass('active');
			$.bbq.pushState( "#/equipe");
		} else if($filosofia-tolerancia <= top && $solucoes-tolerancia >= top ){
			$headNav.eq(2).addClass('active');
			url = $headNav.eq(2).attr('href');
			$.bbq.pushState( "#/filosofia");
		} else if($solucoes-tolerancia <= top && $diferenciais-tolerancia >= top ){
			$headNav.eq(3).addClass('active');
			url = $headNav.eq(3).attr('href');
			$.bbq.pushState( "#/solucoes");
		} else if($diferenciais-tolerancia <= top && $clientes-tolerancia >= top ){
			$headNav.eq(4).addClass('active');
			url = $headNav.eq(4).attr('href');
			$.bbq.pushState( "#/diferenciais");
		} else if($clientes-tolerancia <= top && $depoimentos-tolerancia >= top ){
			$headNav.eq(5).addClass('active');
			url = $headNav.eq(5).attr('href');
			$.bbq.pushState( "#/clientes");
		} else if($depoimentos-tolerancia <= top && $contato-tolerancia >= top ){
			$headNav.eq(6).addClass('active');
			url = $headNav.eq(6).attr('href');
			$.bbq.pushState( "#/depoimentos");
		} else if ($contato-tolerancia < top ){
			$headNav.eq(7).addClass('active');
			url = $headNav.eq(7).attr('href');
			$.bbq.pushState( "#/contato");
		}else{
			$.bbq.pushState( "#/empresa" );
		}

		scrollTarget = false;
		clearTimeout($.data(this, 'scrollTimer'));
		$.data(this, 'scrollTimer', setTimeout(function() {
			scrollTarget = true;
		}, 300));

		/*
		if(top > $height-100) {
			$("header").removeClass().addClass('fixed').stop().fadeTo(700,1);
			$('header#mainHeader nav .links').addClass('pad');
		} else {
			$("header").removeClass();
			$('header#mainHeader nav .links').removeClass('pad');
		}
		*/

	});

});