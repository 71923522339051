/* jshint ignore:start */
//// JavaScript Document

/**
 * Validação de formulario 
 */
function validaForm(oForm) {
	var erro = "";
	var result = true;
	
	//Verifica os campos compreenchimento obrigatório
	//////////////////////////////////////////////////
	for(var i=0;i<(oForm.length-1);i++) {
		if (oForm[i].id !== null && $.trim(oForm[i].id) !== '') {
			var id = "#"+oForm[i].id;
			
			objValue = jQuery.trim(oForm[i].value);
			
			if ($(id).attr('placeholder')) { 
				var placeHolder = $(id).attr('placeholder');
				objValue = (objValue===placeHolder)? "":objValue;
			}
			
			if ($(id).attr('required')) {
				if(objValue=='' && $(id).attr('required').indexOf("required") > -1) {
					destaqueErro(0,oForm[i]);
					if (result) oForm[i].focus();
					result = false;
				}else {
					if($(id).attr('required').indexOf("required") > -1)
						destaqueErro(1,oForm[i]);
				}
			}
		}
	}
	
	if (!result) erro = "Campos com preenchimento obrigatório.\n";

	//Verifica os campos a serem validados
	//////////////////////////////////////////////////
	
	var unique_email = false;
	
	for(var z=0;z<(oForm.length-1);z++) {
		var id = "#"+oForm[z].id;
		
		objValue = jQuery.trim(oForm[z].value);

		if(objValue!='') {
			//console.log(strstr(oForm[z].id, "email"));
			if(strstr(oForm[z].id, "email")) {
				if (!checarEmail(oForm[z].value)) {
					erro += "E-mail inválido.\n";
					destaqueErro(0,oForm[z]);
					result = false;
				}
			}
	
			if(oForm[z].id == "cpf") {
				if (!validaCPF(oForm[z].value)) {
					erro += "CPF inválido.\n";
					destaqueErro(0,oForm[z]);
					result = false;
				}
			}
			
			if(oForm[z].id == "cnpj") {
				if (!validaCNPJ(oForm[z].value)) {
					erro += "CNPJ inválido.\n";
					destaqueErro(0,oForm[z]);
					result = false;
				}
			}

			if(strstr(oForm[z].id, "date")) {
				var d = oForm[z].value.substring(0,2);
				var m = oForm[z].value.substring(3,5);
				var y = oForm[z].value.substring(6,10);
				
				if (!checkdate(m, d, y)) {
					erro += "Data inválida.\n";
					destaqueErro(0,oForm[z]);
					result = false;
				}
			}
	
			if(oForm[z].id == "password" && $("#cpassword").length > 0) {
				var cpassword = $("#cpassword");
				if (oForm[z].value != cpassword.val()) {
					erro += "Campos de Senha não conferem.\n";
					destaqueErro(0,oForm[z]);
					destaqueErro(0,cpassword);
					result = false;
				}
			}
		
		}
	}
	
	if (!result) alert (erro);
		
	return result;

}

/**
 * mudar cor dos campos
 * @param act
 * @param elemento
 */
function destaqueErro(act,elemento) {
	if(act == 0) {
		$(elemento).addClass('campoObrigatorio');
		$(elemento).attr('title', 'campo obrigatório');
	}else{
		$(elemento).removeClass('campoObrigatorio');
		$(elemento).attr('title', 'campo obrigatório');
	}
}

$(document).ready(function(){
	/**
	 * Limitador de carecteres para Textarea
	 */
	$(".textCounter").keypress(function () {
		var maxlimit = $(this).attr("maxlength");
		if ($(this).val().length > maxlimit) {
			$(this).val($(this).val().substring(0, maxlimit));
		}else{
			var resta = maxlimit - $(this).val().length;
			$(this).next(".textCounter-limit").html(resta);
		}
	});
	$(".textCounter").keyup(function () {
		var maxlimit = $(this).attr("maxlength");
		
		if ($(this).val().length > maxlimit) {
			$(this).val($(this).val().substring(0, maxlimit));
		}else{
			var resta = maxlimit - $(this).val().length;
			$(this).next(".textCounter-limit").html(resta);
		}
	});
	
	//INPUT BG-COLOR
	$("input").keyup(function () {
		$(this).removeClass('campoObrigatorio');
	});
	//TEXTAREA BG-COLOR
	$("textarea").keyup(function () {
		$(this).removeClass('campoObrigatorio');
	});
	
	/**
	 * SELECIONANDO AS CIDADES POR ESTADO
	 */
	$("#state").change(function () {
		if ($(this).is("selected")) {
			var estado = $(this).val().split("#");
	
			$.getJSON(URL_BASE+"adm/cidades", {estado_id:estado[0]} , function (data) {
				$('#city option').remove();

				var select = $('#city');
				if(select.prop) {
				  var options = select.prop('options');
				}
				else {
				  var options = select.attr('options');
				}

				for (var d in data) {
					options[d] = new Option(data[d].city, data[d].city);
				}
			});
		}
	});
	
	/**
	 * Recuperando informações do cep
	 */
	$("#cep").change(function () {
		if ($(this).is("input")) {
			var cep = $(this).val();
	
			$.getJSON(URL_BASE+"adm/cep", {cep:cep} , function (data) {
				$("#street").val(data.street);
				$("#district").val(data.district);
				$("#city").val(data.city);
				$("#uf").val(data.uf);
			});
		}
	});
});
/* jshint ignore:end */