function goToTop(e) {
	e.preventDefault();
	//scroll body to top
	$("html, body").animate({
		scrollTop: 0
	}, 800);
}

function scrollaPraBaixo(){
	$('body').on('click', 'span.scroll', function(e){
		var $el = $(this).parent().parent().next();
		$('html, body').stop().animate({
			scrollTop: $($el).offset().top
		}, 1000);
	});
}

function abreInformacaoPessoa(){
	//encontra o top da div equipe
	$equipeTop = $('#equipe').offset().top-130;

	//clica no nome de algum membro da equipe
	$('#equipe .team article').on('click', 'a', function(e){
		e.preventDefault();
		var $this = $(this),
		$pessoa = $this.next(); //encontra a div com o curriculo

		//tira o active de todo mundo
		$('#equipe').find('.pessoa').removeClass('active');
		//adiciona o active no membro da equipe clicado
		$pessoa.addClass('active');

		//scrola pro topo de #equipe	
		$('html, body').stop().animate({
			scrollTop: $equipeTop
		}, 500); 
		

	});

	//fechar equipe
	$('#equipe .pessoa').on('click', 'span.close', function(e){
		//remove active
		$(this).parent().removeClass('active');

		//volta pra posição do link
		$('html, body').stop().animate({
			scrollTop: $(this).parent().parent().offset().top-100
		}, 500); 
	});


}

function carrosselTopo() {
	var $carrosselHome = $('.owl-carousel');
	$carrosselHome.owlCarousel({
		nav:true,
		items: 1,
		navText: []
	});
}

function paddingDivs(){
	var $tamanhoHeader = $('header#mainHeader').innerHeight();
	var $primeiraDiv = $('body main').children();

	$primeiraDiv.animate({
		'padding-top' : $tamanhoHeader
	});
}

$(document).ready(function () {

	$('a.goToTop').on('click', goToTop);

	scrollaPraBaixo();

	abreInformacaoPessoa();

	carrosselTopo();

	paddingDivs();

    $('form input[type=tel]').inputmask({"mask": "(99) 99999999[9]"}); //máscara telefone

});
