/* jshint ignore:start */
/**
 * Valida EMAIL
 */
function checarEmail(campo){
	x = 0;
	email=campo;
	carac_invalido = " /:,;";
	if (email == ""){
		return false;
	}
	for (i=0; i<carac_invalido.length; i++){
  	carac_errado = carac_invalido.charAt(i);
  	if (email.indexOf(carac_errado,0) > -1){
			return false;
  	}
	}

	atPos = email.indexOf("@",1);
	if (atPos == -1){
		return false;
	}

	if (email.indexOf("@",atPos+1) != -1){
		return false;
	}
	periodPos = email.indexOf(".",atPos);

	if (periodPos == -1){
		return false;
	}

	if (periodPos+3 > email.length){
		return false;
	}
	return true;
}

/**
 * Valida CPF
 */
function validaCPF(campo){	
	cpf = campo.substr(0,3) + campo.substr(4,3) + campo.substr(8,3) + campo.substr(12,2);

	if (campo == "111.111.111-11" || campo == "222.222.222-22" || campo == "333.333.333-33" || campo == "444.444.444-44" || campo == "555.555.555-55"
		|| campo == "666.666.666-66" || campo == "777.777.777-77" || campo == "888.888.888-88" || campo == "999.999.999-99" || campo == "000.000.000-00" ) {
		return false;
	}
	
	var i; 
	s=cpf; 
	var c = s.substr(0,9); 
	var dv = s.substr(9,2); 
	var d1 = 0; 
	  
	for (i = 0; i < 9; i++) { 
		d1 += c.charAt(i)*(10-i); 
	} 
	  
	if (d1 == 0){ 
		return false;
	} 
	
	d1 = 11 - (d1 % 11); 

	if (d1 > 9) d1 = 0; 
	
	if (dv.charAt(0) != d1){ 
		return false;
	} 
	d1 *= 2; 

	for (i = 0; i < 9; i++){ 0  
		d1 += c.charAt(i)*(11-i); 
	} 
	
	d1 = 11 - (d1 % 11); 
	if (d1 > 9) d1 = 0; 
	
	if (dv.charAt(1) != d1){ 
		return false;
	}

	return true;
}

/**
 * Valida DATA
 */
function checkdate( month, day, year ) {
	// http://kevin.vanzonneveld.net
	// +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
	// *	 example 1: checkdate(12, 31, 2000);
	// *	 returns 1: true
	// *	 example 2: checkdate(2, 29, 2001);
	// *	 returns 2: false
	// *	 example 3: checkdate(03, 31, 2008);
	// *	 returns 3: true
	
	var myDate = new Date();
	myDate.setFullYear( year, (month - 1), day );
	
	return ( (myDate.getMonth()+1) == month );
}

/**
 * Localiza a primeira ocorrência de uma string dentro de outra
 * 
 * @param haystack
 * @param needle
 * @param bool
 * @returns
 */
function strstr (haystack, needle, bool) {
	// Finds first occurrence of a string within another  
	// 
	// version: 1109.2015
	// discuss at: http://phpjs.org/functions/strstr
	// +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
	// +   bugfixed by: Onno Marsman
	// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
	// *	 example 1: strstr('Kevin van Zonneveld', 'van');
	// *	 returns 1: 'van Zonneveld'
	// *	 example 2: strstr('Kevin van Zonneveld', 'van', true);
	// *	 returns 2: 'Kevin '
	// *	 example 3: strstr('name@example.com', '@');
	// *	 returns 3: '@example.com'
	// *	 example 4: strstr('name@example.com', '@', true);
	// *	 returns 4: 'name'
	var pos = 0;
 
	haystack += '';
	pos = haystack.indexOf(needle);
	if (pos == -1) {
		return false;
	} else {
		if (bool) {
			return haystack.substr(0, pos);
		} else {
			return haystack.slice(pos);
		}
	}
}

function validaCNPJ(cnpj) {
	cnpj = cnpj.replace(/[^\d]+/g,'');
 
	if(cnpj == '') return false;
	 
	if (cnpj.length != 14)
		return false;
 
	// Elimina CNPJs invalidos conhecidos
	if (cnpj == "00000000000000" || 
		cnpj == "11111111111111" || 
		cnpj == "22222222222222" || 
		cnpj == "33333333333333" || 
		cnpj == "44444444444444" || 
		cnpj == "55555555555555" || 
		cnpj == "66666666666666" || 
		cnpj == "77777777777777" || 
		cnpj == "88888888888888" || 
		cnpj == "99999999999999")
		return false;
		 
	// Valida DVs
	tamanho = cnpj.length - 2
	numeros = cnpj.substring(0,tamanho);
	digitos = cnpj.substring(tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (i = tamanho; i >= 1; i--) {
	  soma += numeros.charAt(tamanho - i) * pos--;
	  if (pos < 2)
			pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado != digitos.charAt(0))
		return false;

	
	tamanho = tamanho + 1;
	numeros = cnpj.substring(0,tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (i = tamanho; i >= 1; i--) {
	  soma += numeros.charAt(tamanho - i) * pos--;
	  if (pos < 2)
			pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado != digitos.charAt(1))
		  return false;
	   
	return true;
	
}
/* jshint ignore:end */