function carrosselSolucoes(){
	var $carrosselSolucoes = $('#solucoes .carrossel');
	$carrosselSolucoes.owlCarousel({
		loop:false,
		nav:false,
		items: 1,
		autoHeight:true,
	});

	$('#solucoes .navigation span.btNext').click(function() {
		$carrosselSolucoes.trigger('next.owl.carousel');
	});

	// Go to the previous item
	$('#solucoes .navigation span.btPrev').click(function() {
		$carrosselSolucoes.trigger('prev.owl.carousel');
	});

	//numero total de itens
	var totalItemsCarousel = $('#solucoes .list').length;
	$('#solucoes .navigation .numeros span.total').html(totalItemsCarousel);

	$carrosselSolucoes.on('changed.owl.carousel', function(e) {
		if (!e.namespace || e.property.name != 'position') return;
		$('#info').text(e.item.index + '/' + e.item.count);
		//slide atual
		$('#solucoes .navigation .numeros span.atual').html(e.item.index +1);
		//numero total
		$('#solucoes .navigation .numeros span.total').html(e.item.count);
	});

	$carrosselSolucoes.on('initialized.owl.carousel', function(e) {
		setOwlStageHeight("#solucoes");
	});
	$carrosselSolucoes.on('resized.owl.carousel', function(e) {
		setOwlStageHeight("#solucoes");
	});
	$carrosselSolucoes.on('next.owl.carousel', function(e) {
		setOwlStageHeight("#solucoes");
	});
	$carrosselSolucoes.on('prev.owl.carousel', function(e) {
		setOwlStageHeight("#solucoes");
	});
}

//Altura dos carrosseis
function setOwlStageHeight($element) {
	var maxHeight = 0;
	$($element+' .owl-item.active').each(function () { // LOOP THROUGH ACTIVE ITEMS
		// console.log($(this));
		var thisHeight = parseInt( $(this).first().height() );
		maxHeight=(maxHeight>=thisHeight?maxHeight:thisHeight);
	});

	console.log(maxHeight);
	$($element+' .owl-carousel').css('height', maxHeight );
	$($element+' .owl-stage-outer').css('height', maxHeight ); // CORRECT DRAG-AREA SO BUTTONS ARE CLICKABLE
}


$(document).ready(function () {
	carrosselSolucoes();
});

$(window).load(function () {
	setOwlStageHeight("#solucoes");
});