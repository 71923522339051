function carrosselDepoimentos() {
	var $carrosselDepoimentos = $('#depoimentos .content .carrossel');
	$carrosselDepoimentos.owlCarousel({
		loop: true,
		autoplay:true,
		autoplayHoverPause: true,
		nav:true,
		items: 1,
		navText: []
	});
}

$(document).ready(function () {
	carrosselDepoimentos();
});