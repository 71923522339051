function carrosselDiferenciais() {
	var $carrosselDiferenciais = $('#diferenciais .esq .carrossel');
	$carrosselDiferenciais.owlCarousel({
		loop:false,
		nav:true,
		items: 1,
		navText: [],
		autoHeight: true
	});
}

$(document).ready(function () {
	carrosselDiferenciais();
});